<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="文件所属用户：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入昵称" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="userPayTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="用户ID"  align="center" width="180">
          <template #default="scope">{{scope.row.user_id}}</template>
        </el-table-column>
        <el-table-column label="用户昵称"  align="center">
          <template #default="scope">{{scope.row.nick_name}}</template>
        </el-table-column>
        <el-table-column label="文件信息"  align="center">
          <template #default="scope">{{scope.row.file_name}}</template>
        </el-table-column>
        <el-table-column label="订单编号" align="center">
          <template #default="scope">{{scope.row.tradeno}}</template>
        </el-table-column>
        <el-table-column label="订单金额" align="center">
          <template #default="scope">{{scope.row.buy_money}}</template>
        </el-table-column>
        <el-table-column label="支付时间" align="center">
          <template #default="scope">{{scope.row.buy_time}}</template>
        </el-table-column>
        <el-table-column label="文件所属用户" align="center">
          <template #default="scope">{{scope.row.superior_nick_name}}</template>
        </el-table-column>
        <el-table-column label="返佣比例(%)" align="center">
          <template #default="scope">{{scope.row.return_code}}</template>
        </el-table-column>
        <el-table-column label="返佣金额" align="center">
          <template #default="scope">{{scope.row.superior_rebate}}</template>
        </el-table-column>

      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>

import {fetchList} from "@/api/fileDisOrder";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
};


export default {
  name: 'DisOrder',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },

    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
            if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
            }
      })
    },

  }
}
</script>
<style scoped>


</style>

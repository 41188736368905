import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/user/file/fileDisList.php',
    method : 'post',
    data : data
  })
}
